<template>
  <div>
    <!-- Stats-->
    <div class="stats services news-w3layouts" style="margin-top: 0">
      <div class="container">
        <div class="stats-agileinfo agileits-w3layouts">
          <div class="col-sm-2 col-xs-6 stats-grid" id="service-btn-1">
            <div class="numscroller numscroller-big-bottom">免费版</div>
            <h6>智能专业定位</h6>
            <div class="service-btn-tip">点击查看</div>
          </div>
          <div class="col-sm-2 col-xs-6 stats-grid" id="service-btn-2">
            <div class="numscroller numscroller-big-bottom">VIP版</div>
            <h6>智能专业定位</h6>
            <div class="service-btn-tip">点击查看</div>
          </div>
          <div class="col-sm-2 col-xs-6 stats-grid" id="service-btn-3">
            <div class="numscroller numscroller-big-bottom">解读版</div>
            <h6>智能专业定位</h6>
            <div class="service-btn-tip">点击查看</div>
          </div>
          <div class="col-sm-2 col-xs-6 stats-grid" id="service-btn-4">
            <div class="numscroller numscroller-big-bottom">免费版</div>
            <h6>学科能力测评</h6>
            <div class="service-btn-tip">点击查看</div>
          </div>
          <div class="col-sm-2 col-xs-6 stats-grid" id="service-btn-5">
            <div class="numscroller numscroller-big-bottom">VIP版</div>
            <h6>学科能力测评</h6>
            <div class="service-btn-tip">点击查看</div>
          </div>
          <div class="col-sm-2 col-xs-6 stats-grid" id="service-btn-6">
            <div class="numscroller numscroller-big-bottom">解读版</div>
            <h6>学科能力测评</h6>
            <div class="service-btn-tip">点击查看</div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <!-- services-->
    <div class="services" id="service-page-1">
      <div class="container">
        <div class="col-md-4 services-w3ls-left">
          <h3 class="agileits-title" style="font-size: 3.2em;">
            智能专业定位系统（免费版）
          </h3>
          <p>
            免费版包括 2 个免费的内在特质测评报告：职业倾向报告和人格倾向报告
          </p>
          <a class="product-btn" @click="jumpPage('HomeIndexMajorIndex')"
            >完全免费</a
          >
        </div>
        <div class="col-md-8 services-w3ls-row agileits-w3layouts">
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <h5>职业倾向报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
            <h5>人格倾向报告</h5>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="services" id="service-page-2">
      <div class="container">
        <div class="col-md-4 services-w3ls-left">
          <h3 class="agileits-title" style="font-size: 3.2em;">
            智能专业定位系统（VIP版）
          </h3>
          <p>
            VIP 版不仅包括 2 个免费的内在特质测评报告，还包括 5 个VIP版本报告。
          </p>
          <a class="product-btn" @click="jumpPage('HomeIndexMajorIndex')"
            >￥299</a
          >
        </div>
        <div class="col-md-8 services-w3ls-row agileits-w3layouts">
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <h5>职业倾向报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
            <h5>人格倾向报告</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl agileits-w3layouts"
          >
            <i class="fa fa-recycle" aria-hidden="true"></i>
            <h5>创新指数报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl">
            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
            <h5>最匹配与最不匹配的大类专业报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bookmark-o" aria-hidden="true"></i>
            <h5>最匹配专业详细报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-heart-o" aria-hidden="true"></i>
            <h5>最适合大类专业内小类专业排序报告</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>高校大类专业全匹配报告</h5>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="services" id="service-page-3">
      <div class="container">
        <div class="col-md-4 services-w3ls-left">
          <h3 class="agileits-title" style="font-size: 3.2em;">
            智能专业定位系统（VIP咨询版）
          </h3>
          <p>
            VIP 咨询版不仅包括所有 VIP
            版报告，另外我们还向您提供学业规划老师的面对面咨询指导。届时您将得到资深的学业规划专家对深度报告解读，并为您提供量身定做的生涯规划方案。
          </p>
          <a class="product-btn" @click="jumpPage('HomeIndexMajorIndex')"
            >￥1299</a
          ><span>&nbsp;</span
          ><a class="product-btn" href="/Home/Index/main_members">专家介绍</a>
        </div>
        <div class="col-md-8 services-w3ls-row agileits-w3layouts">
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <h5>职业倾向报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
            <h5>人格倾向报告</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl agileits-w3layouts"
          >
            <i class="fa fa-recycle" aria-hidden="true"></i>
            <h5>创新指数报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl">
            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
            <h5>最匹配与最不匹配的大类专业报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bookmark-o" aria-hidden="true"></i>
            <h5>最匹配专业详细报告</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-heart-o" aria-hidden="true"></i>
            <h5>最适合大类专业内小类专业排序报告</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>高校大类专业全匹配报告</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>专家深度解读</h5>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="services" id="service-page-4">
      <div class="container">
        <div class="col-md-4 services-w3ls-left">
          <h3 class="agileits-title" style="font-size: 3.2em;">
            学科能力定位测评系统（免费版）
          </h3>
          <p>
            对语文学科进行科学能力测评，帮助学生在高中一、二年级就可助考生提前锁定高考选科科目，学习更有目标。
          </p>
          <a class="product-btn" href="/Home/Index/subjectindex">完全免费</a>
        </div>
        <div class="col-md-8 services-w3ls-row agileits-w3layouts">
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <h5>语文学科能力测评</h5>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="services" id="service-page-5">
      <div class="container">
        <div class="col-md-4 services-w3ls-left">
          <h3 class="agileits-title" style="font-size: 3.2em;">
            学科能力定位测评系统（VIP版）
          </h3>
          <p>
            对 9 门学科（语数外 + 6
            门选考科目）进行科学能力测评，帮助学生在高中一、二年级就可助考生提前锁定高考选科科目，学习更有目标。
          </p>
          <a class="product-btn" href="/HomeIndexSubjectindex">￥199</a>
        </div>
        <div class="col-md-8 services-w3ls-row agileits-w3layouts">
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <h5>语文学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
            <h5>数学学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl agileits-w3layouts"
          >
            <i class="fa fa-recycle" aria-hidden="true"></i>
            <h5>外语学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl">
            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
            <h5>政治学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bookmark-o" aria-hidden="true"></i>
            <h5>历史学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-heart-o" aria-hidden="true"></i>
            <h5>地理学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>物理学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>化学学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>生物学科能力测评</h5>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="services" id="service-page-6">
      <div class="container">
        <div class="col-md-4 services-w3ls-left">
          <h3 class="agileits-title" style="font-size: 3.2em;">
            学科能力定位测评系统（VIP解读版）
          </h3>
          <p>
            对 9 门学科（语数外 + 6
            门选考科目）进行科学能力测评，帮助学生在高中一、二年级就可助考生提前锁定高考选科科目，学习更有目标。另外我们还向您提供专家的面对面咨询指导，届时您将得到资深的专家对深度报告解读。
          </p>
          <a class="product-btn" href="/HomeIndexSubjectindex">￥1199</a
          ><span>&nbsp;</span
          ><a class="product-btn" href="/Home/Index/main_members">专家介绍</a>
        </div>
        <div class="col-md-8 services-w3ls-row agileits-w3layouts">
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <h5>语文学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
            <h5>数学学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl agileits-w3layouts"
          >
            <i class="fa fa-recycle" aria-hidden="true"></i>
            <h5>外语学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid srvs-wthree-mdl">
            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
            <h5>政治学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-bookmark-o" aria-hidden="true"></i>
            <h5>历史学科能力测评</h5>
          </div>
          <div class="col-md-6 col-xs-4 services-grid agileits-w3layouts">
            <i class="fa fa-heart-o" aria-hidden="true"></i>
            <h5>地理学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>物理学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>化学学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>生物学科能力测评</h5>
          </div>
          <div
            class="col-md-6 col-xs-4 services-grid bottom-grids"
            style="margin-top: 2em;"
          >
            <i class="fa fa-industry" aria-hidden="true"></i>
            <h5>专家深度解读</h5>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <!-- //services-->
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: '',
  data: function () {
    return {}
  },
  mounted: function () {
    function showPage (page) {
      for (var i = 1; i <= 6; i++) {
        $('#service-page-' + i).hide()
      }
      $('#service-page-' + page).show()
    }
    showPage(1)
    for (var i = 1; i <= 6; i++) {
      $('#service-btn-' + i).click(function () {
        var idx = Number(
          $(this)
            .attr('id')
            .replace('service-btn-', '')
        )
        showPage(idx)
      })
    }
  },
  methods: {}
}
</script>

<style lang="less">
</style>
